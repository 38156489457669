<template>
  <div class="d-flex flex-column fill-height pb-16">
    <PageHeader>
      <div class="d-flex align-center">
        <h1 class="text-title-1">Files</h1>
        <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
        <span class="text-title-1 accent--text">Projects</span>
      </div>
    </PageHeader>

    <v-container>
      <div v-if="!loading" class="d-flex align-center justify-space-between mb-9">
        <div class="d-flex">
          <UiGridViewType class="mr-6" :list="$config.project.gridView.thumbnail"
                          :active="(getGridView.filesByProjects.gridView.type === 'thumbnail' && getGridView.filesByProjects.gridView)"
                          @setGridView="setGridView({key: 'filesByProjects', value: {gridView: $event, isGrid: true}})"/>

          <UiGridViewType class="mr-7" :list="$config.project.gridView.list"
                          :active="(getGridView.filesByProjects.gridView.type === 'list' && getGridView.filesByProjects.gridView)"
                          @setGridView="setGridView({key: 'filesByProjects', value: {gridView: $event, isGrid: false}})"/>

          <v-switch
            flat
            dense
            hide-details
            inset
            @change="$router.push({name: 'FilesAll', params: {space_id: $route.params.space_id}})"
            class="switcher my-0"
          >
            <template #label>
              <span class="text-captions-1 font-weight-semi-bold accent--text">View all files</span>
            </template>
          </v-switch>
        </div>

        <div class="d-flex align-center">
          <UiFilter
            :filterData="filterData"
            offset-y
            allow-overflow
            left
            clear-btn
            @setFilters="setFilters"
            openFilterBtnClasses="mr-4"
          />
          <UiSort
            class="mr-6"
            mandatory1
            mandatory2
            :list="sortList1"
            :list2="sortList2"
            :value1="sort_1"
            :value2="sort_2"
            @setSort1="value => this.setSort('sort_1', value)"
            @setSort2="value => this.setSort('sort_2', value)"
          />
          <UiSearch style="width: 194px" @onSearch="searchProject"/>
        </div>
      </div>

      <div v-if="projectsLoading || loading" class="d-flex justify-center pt-16">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </div>

      <div v-if="projectsListFiltered.length && !loading">
        <div v-show="getGridView.filesByProjects.isGrid" :class="['card-wr', getGridView.filesByProjects.gridView.slug]">
          <UiGridCard
            v-for="project in projectsListFiltered"
            :key="project.id"
            :item="project"
            :itemStatus="project.status"
            :selectable="false"
            :selected="project.checked"
            :to="!project.showRenameInput ? {name: 'FilesByStyles', params: {project_id: project.id, space_id: $route.params.space_id}} : null"
          >
            <template #header>
              <div class="d-flex justify-space-between py-3 px-3">
                <UiAvatar
                  size="22"
                  :text="project.user.first_name"
                  :src="project.user.avatar && project.user.avatar.url || ''"
                />
                <UiSelectedIndicator
                  :selected="project.checked"
                  @click.prevent="updateProject({id: project.id, checked: !project.checked})"
                />
              </div>
            </template>

            <template #image>
              <div class="d-flex align-center justify-center flex-grow-1 pb-5">
                <IconFolder width="38%" class="gray-30--text"/>
              </div>
            </template>

            <template #status>
              <UiProjectStatus :id="project.status" block/>
            </template>

            <template #bottom>
              <div class="px-2">
                <UiChangeNameInput
                  v-if="project.showRenameInput"
                  v-model="project.newTitle"
                  placeholder="Enter file name"
                  :rules="'required|max:100'"
                  @onSave="updateProjectName(project)"
                  @onClose="project.showRenameInput = false"
                />
                <v-tooltip top color="accent" v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <h6
                      v-bind="attrs"
                      v-on="on"
                      @click.prevent="canRename(project) ? project.showRenameInput = true : $event.preventDefault"
                      class="mb-1 text-body font-weight-semi-bold gray-100--text text-truncate"
                    >
                      {{ project.title }}
                    </h6>
                  </template>
                  <span class="white--text font-weight-medium ">{{ project.title }}</span>
                </v-tooltip>

                <div class="d-flex align-end justify-space-between">
                  <div>
                    <span class="text-captions-1 gray-60--text">Updated:</span>
                    <p class="mb-0 text-captions-1 font-weight-bold gray-80--text">
                      {{ timeDistance(project.updated_at) }}</p>
                  </div>
                  <FilesProjectMenu :project="project" :projects-list="projectsList" :filters="filters"
                                    @updateProjectsList="updateProjectsList"/>
                </div>
              </div>
              <div v-if="project.loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
                   style="z-index: 2;" @click.prevent>
                <v-progress-circular
                  :size="64"
                  color="accent"
                  indeterminate
                />
              </div>
            </template>
          </UiGridCard>
        </div>

        <UiListCard
          v-show="!getGridView.filesByProjects.isGrid"
          v-for="project in projectsListFiltered"
          :key="project.id"
          :item="project"
          :itemStatus="project.status"
          :selectable="false"
          :selected="project.checked"
          :class="[`project mb-3 relative ${getGridView.filesByProjects.gridView.slug}`]"
          :to="!project.showRenameInput ? {name: 'FilesByStyles', params: {project_id: project.id, space_id: $route.params.space_id}} : null"
        >
          <template v-slot:image>
            <UiSelectedIndicator
              class="absolute mt-2 ml-2"
              :selected="project.checked"
              @click.prevent="updateProject({id: project.id, checked: !project.checked})"
            />
            <div class="d-flex align-center justify-center fill-width fill-height">
              <IconFolder width="28%" class="gray-30--text"/>
            </div>
          </template>

          <template v-slot:body>
            <div class="d-flex align-center fill-width">
              <div class="project-section px-6 mr-auto">
                <div class="d-flex align-center mb-1 text-captions-1">
                  <UiChangeNameInput
                    v-if="project.showRenameInput"
                    v-model="project.newTitle"
                    placeholder="Enter file name"
                    :rules="'required|max:100'"
                    @onSave="updateProjectName(project)"
                    @onClose="project.showRenameInput = false"
                  />
                  <v-tooltip top color="accent" v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        @click.prevent="canRename(project) ? project.showRenameInput = true : $event.preventDefault"
                        class="mb-0 text-body font-weight-semi-bold gray-100--text text-truncate"
                      >
                        {{ project.title }}
                      </p>
                    </template>
                    <span class="white--text font-weight-medium ">{{ project.title }}</span>
                  </v-tooltip>

                </div>
                <p class="mb-0 text-captions-1">
                  <strong class="font-weight-bold accent--text">{{ project.total_files }}</strong>
                  {{ project.total_files === 1 ? 'File' : 'Files' }}
                </p>
              </div>

              <div class="project-section px-6 border-left">
                <p class="mb-1 text-captions-1 gray-60--text">
                  Updated: <strong class="font-weight-semi-bold">{{ timeDistance(project.updated_at) }}</strong>
                </p>
                <p class="mb-0 text-captions-1">
                  Due:
                  <strong class="font-weight-semi-bold gray-100--text">{{ dueDaysLeft(project.due_date) }}
                    {{ dueDaysLeft(project.due_date) === 1 ? 'day' : 'days' }}</strong>
                </p>
              </div>

              <div class="project-section px-6 border-left">
                <div class="text-captions-1 text-overflow-ellipsis text-no-wrap overflow-hidden">
                  By:
                  <UiAvatar :text="project.user.first_name" size="20" class="ml-1"/>
                  {{ projectOwner(project) }}
                </div>
              </div>

              <div class="project-section px-6 border-left">
                <div class="d-flex justify-end">
                  <FilesProjectMenu :project="project" :projects-list="projectsList" :filters="filters"
                                    @updateProjectsList="updateProjectsList"/>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <div v-if="project.loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
                 style="z-index: 2;" @click.prevent>
              <v-progress-circular
                :size="48"
                color="accent"
                indeterminate
              />
            </div>
          </template>
        </UiListCard>

        <PageFooter v-if="showBulkActions" class="px-0">
          <BulkActionsBar :projects-list="projectsList" :filters="filters" @updateProjectsList="updateProjectsList"/>
        </PageFooter>

        <ShareProjectModal/>
      </div>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {differenceInDays, format, formatDistanceToNow} from "date-fns";
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import UiSearch from "@/components/UI/UiSearch";
import UiGridViewType from "@/components/UI/UiGridViewType"
import UiSort from "@/components/UI/UiSort"
import UiFilter from "@/components/UI/UiFilter"
import UiGridCard from "@/components/UI/UiGridCard"
import UiListCard from "@/components/UI/UiListCard";
import UiAvatar from "@/components/UI/UiAvatar";
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput";
import ShareProjectModal from "@/components/modals/ShareProjectModal";
import FilesProjectMenu from "@/views/files/by-projects/FilesProjectMenu";
import BulkActionsBar from "@/views/files/by-projects/BulkActionsBar";

export default {
  name: 'FilesByProjectsPage',
  components: {
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    IconFolder: () => import('@/components/icons/IconFolder'),

    PageHeader,
    PageFooter,
    UiSearch,
    UiGridViewType,
    UiSort,
    UiFilter,
    UiGridCard,
    UiListCard,
    UiAvatar,
    UiProjectStatus,
    UiSelectedIndicator,
    UiChangeNameInput,
    ShareProjectModal,
    FilesProjectMenu,
    BulkActionsBar,
  },
  data() {
    return {
      sortList1: [
        {name: 'Name', slug: 'title'},
        {name: 'Modified', slug: 'updated_at'},
        {name: 'Modified By', slug: 'updated_by'},
        {name: 'Due Date', slug: 'due_date'}
      ],
      sortList2: [
        {name: 'Ascending', slug: 'asc'},
        {name: 'Descending', slug: 'desc'}
      ],
      loading: true,
      projectsLoading: false,
      projectsList: [],
      filters: {
        category: [],
        status: [],
        workflow_id: [],
      },
      filterData: [],
      sort_1: {name: 'Modified', slug: 'updated_at'},
      sort_2: {name: 'Descending', slug: 'desc'},
      search: '',
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getUser',
      'getPermission',
      'getActiveSpace',
      "getGridView"
    ]),
    projectsListFiltered() {
      return this.projectsList.filter(project => project.total_files);
    },
    showBulkActions() {
      return this.projectsList.some(project => project.checked);
    },
  },
  async created() {
    const {search = '', category, status, workflow_id, sort_1, sort_2} = this.$route.query;
    const filters = {
      category: category === '' || category === undefined ? [] : category.split(',').map(i => +i),
      status: status === '' || status === undefined ? [] : status.split(',').map(i => +i),
      workflow_id: workflow_id === '' || workflow_id === undefined ? [] : workflow_id.split(',').map(i => +i),
    };

    if (this.$route.params.space_id !== 'default') {
      filters.space_id = this.$route.params.space_id
      await this.$store.dispatch('setActiveSpace', this.$route.params.space_id)
    } else {
      filters.space_id = null
    }

    this.filters = filters;
    this.search = search;

    if (sort_1 !== '' && sort_1 !== undefined) {
      this.sort_1 = this.sortList1.find(sort => sort.slug === sort_1);
    }

    if (sort_2 !== '' && sort_2 !== undefined) {
      this.sort_2 = this.sortList2.find(sort => sort.slug === sort_2);
    }

    await Promise.all([this.loadProjects(), this.initFiltersData()]);
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'setGridView'
    ]),
    canRename(project) {
      return this.getPermission(project.id).specification['can-edit-specification'];
    },
    async loadProjects() {
      try {
        const res = await this.$api.project.list({
          ...this.filters,
          count: -1,
          search: this.search,
          sort_by: `${this.sort_1.slug}|${this.sort_2.slug}`,
          space_id: this.getActiveSpace?.id || null
        });

        this.projectsList = res.data.map(project => ({
          ...project,
          newTitle: project.title,
          showRenameInput: false
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.projectsLoading = false;
      }
    },
    setFilters({data: newVal}) {
      this.filterData = newVal;
      this.filterData.forEach(el => {
        if (Array.isArray(el.activeFilters)) {
          this.filters[el.type] = el.activeFilters.map(i => i.toString())
        } else {
          if (el.activeFilters) {
            this.filters[el.type] = [...el.activeFilters]
          } else {
            this.filters[el.type] = []
          }
        }
      });


      this.projectsLoading = true;
      this.$router.$updateQueryParams(this.filters);
      this.loadProjects();
    },
    async initFiltersData() {
      const existingFilters = this.filters;
      const [categories, workflowProcess] = await Promise.all([this.$api.category.list(), this.$api.workflowScheme.list()]);

      const statuses = Object.keys(this.$config.project.statusLabels).reduce((acc, status) => {
        acc.push({id: +status, title: this.$config.project.statusLabels[status]});
        return acc;
      }, []);

      const workflowFiltered = workflowProcess.filter(schema => {
        return schema.space_id === this.getActiveSpace?.id || null
      })

      this.filterData = [
        {
          type: 'category',
          title: 'Category',
          list: categories.data,
          isMultiple: true,
          activeFilters: existingFilters['category'] || []
        },
        {
          type: 'status',
          title: 'Status',
          list: statuses,
          isMultiple: true,
          activeFilters: existingFilters['status'] || []
        },
      ]

      if (workflowFiltered.length) {
        this.filterData.push({
          type: 'workflow_id',
          title: 'Workflow Process',
          list: workflowFiltered,
          isMultiple: true,
          activeFilters: existingFilters['workflow_id'] || []
        },)
      }
    },
    searchProject(searchVal) {
      this.search = searchVal;

      this.$router.$updateQueryParams({search: searchVal});
      this.projectsLoading = true;
      this.loadProjects();
    },
    async setSort(key, value) {
      this[key] = value;
      this.$router.$updateQueryParams({[key]: value.slug});

      this.projectsLoading = true;
      await this.loadProjects();
    },
    updateProjectsList(data) {
      this.projectsList = data;
    },
    updateProject(data) {
      this.projectsList = this.projectsList.map(project => project.id === data.id ? {...project, ...data} : project);
    },
    async updateProjectName(project) {
      if (!this.canRename(project)) return;

      this.updateProject({id: project.id, loading: true});
      try {
        await this.$api.project.update(project.id, {
          title: project.newTitle,
          tags: project.tag.map((i) => {
            return i.id
          })
        });
        this.updateProject({id: project.id, title: project.newTitle});
      } catch (error) {
        console.error(error);
      } finally {
        this.updateProject({id: project.id, loading: false, showRenameInput: false});
      }
    },
    projectOwner(project) {
      let lastName = project.user.last_name ? `${project.user.last_name.split('')[0]}.` : '';
      return `${project.user.first_name} ${lastName}`;
    },
    timeDistance(date) {
      const now = new Date();
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), 'MM-dd-yyyy');
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '');
      }
    },
    dueDaysLeft(date) {
      const now = new Date();
      const dueData = new Date(date);
      return differenceInDays(dueData, now);
    },
  }
}
</script>

<style scoped lang="scss">
.switcher {
  ::v-deep .v-input--switch__thumb {
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
  }

  &.v-input--switch--inset ::v-deep .v-input--switch__track {
    top: calc(50% - 7px);
    left: -2px;
    height: 14px;
    width: 24px;
    background-color: var(--v-gray-60-base);
  }

  ::v-deep .v-input--selection-controls__ripple {
    top: calc(50% - 21px);
    left: -11px;
  }

  ::v-deep .v-input--selection-controls__input {
    width: 24px;
  }
}

.card-wr {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  &.large {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &.medium {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.small {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .card {
    &:not(.selected) {
      border-color: var(--v-gray-30-base);
    }

    &:hover {
      border-color: var(--v-gray-60-base);
      background-color: var(--v-gray-10-base);
    }
  }
}

.project {
  border-color: var(--v-gray-30-base);

  .project-section {
    width: 25%;
  }

  ::v-deep .ui-list-card__image {
    background-color: transparent;
  }

  &.small {
    ::v-deep .ui-list-card__image {
      width: 86px;
    }

    ::v-deep .ui-list-card__content {
      max-width: calc(100% - 86px);
    }
  }
}

.border-left::before {
  content: '';
  position: absolute;
  top: 18px;
  bottom: 18px;
  margin-left: -24px;
  border-left: 1px solid var(--v-gray-20-base);
}
</style>
